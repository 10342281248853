{
  "en": {
    "js": {
      "maturity_ratings": {
        "maturity_rating/name": {
          "acb_0": "General",
          "acb_12": "Mature",
          "acb_15": "MA15+",
          "acb_18": "R18+",
          "acb_7": "Parental Guidance",
          "bbfc_12": 12,
          "bbfc_12a": "12A",
          "bbfc_15": 15,
          "bbfc_18": 18,
          "bbfc_pg": "PG",
          "bbfc_r18": "R18",
          "bbfc_u": "U",
          "cadpa_12": "12+",
          "cadpa_16": "16+",
          "cadpa_8": "8+",
          "cero_a": "A",
          "cero_b": "B",
          "cero_c": "C",
          "cero_d": "D",
          "cero_educational": "教育・データベース",
          "cero_planned": "審査予定",
          "cero_trial": "規定適合",
          "cero_z": "Z",
          "djctq_10": 10,
          "djctq_12": 12,
          "djctq_14": 14,
          "djctq_16": 16,
          "djctq_18": 18,
          "djctq_er": "ER",
          "djctq_l": "L",
          "eocs_0": "一般ソフト作品（一般作品）",
          "eocs_12": "一般ソフト作品（１２歳以上推奨作品）",
          "eocs_15": "一般ソフト作品（１５歳以上推奨作品）",
          "eocs_18": "１８才未満者への販売禁止ソフト作品（１８禁作品）",
          "esra_12": 12,
          "esra_15": 15,
          "esra_18": 18,
          "esra_25": 25,
          "esra_3": 3,
          "esra_7": 7,
          "esrb_ao": "AO",
          "esrb_e": "E",
          "esrb_e10": "E10+",
          "esrb_ec": "eC",
          "esrb_m": "M",
          "esrb_rp": "RP",
          "esrb_t": "T",
          "grac_0": "전체이용가 (All)",
          "grac_12": "12세이용가 (12+)",
          "grac_15": "15세이용가 (15+)",
          "grac_18": "청소년이용불가 (18+)",
          "grb_0": "전체이용가 (All)",
          "grb_12": "12세이용가 (★)",
          "grb_15": "15세이용가 (★★)",
          "grb_18": "청소년이용불가 (★★★)",
          "gsrr_0": "0+ 普遍級",
          "gsrr_12": "12+ 輔12級",
          "gsrr_15": "15+ 輔15級",
          "gsrr_18": "18+ 限制級",
          "gsrr_6": "6+ 保護級",
          "iarc_12": "12+",
          "iarc_16": "16+",
          "iarc_18": "18+",
          "iarc_3": "3+",
          "iarc_7": "7+",
          "oflc_au_0": "General",
          "oflc_au_13": "R13",
          "oflc_au_15": "R15",
          "oflc_au_16": "R16",
          "oflc_au_18": "R18+",
          "oflc_au_7": "Parental Guidance",
          "oflc_au_mature": "Mature",
          "oflc_nz_g": "G",
          "oflc_nz_m": "M",
          "oflc_nz_pg": "PG",
          "oflc_nz_r": "Restricted",
          "oflc_nz_r13": "Restricted 13",
          "oflc_nz_r15": "Restricted 15",
          "oflc_nz_r16": "Restricted 16",
          "oflc_nz_r18": "Restricted 18",
          "oflc_nz_rp13": "RP13",
          "oflc_nz_rp16": "RP16",
          "oflc_nz_rp18": "RP18",
          "pegi_0": null,
          "pegi_12": 12,
          "pegi_16": 16,
          "pegi_18": 18,
          "pegi_3": 3,
          "pegi_7": 7,
          "segob_a": "A",
          "segob_b": "B",
          "segob_b15": "B15",
          "segob_c": "C",
          "segob_d": "D",
          "segob_p": "P",
          "usk_0": "USK ab 0 freigegeben",
          "usk_12": "USK ab 12 freigegeben",
          "usk_16": "USK ab 16 freigegeben",
          "usk_18": "USK ab 18",
          "usk_6": "USK ab 6 freigegeben"
        }
      }
    }
  },
  "ja": {
    "js": {
      "business_models": {
        "business_model/name": {
          "all_free": "無料",
          "free_to_play": "基本無料(ゲーム内課金あり)",
          "pay_to_play": "ソフト購入(買い切り)",
          "subscription": "定額制"
        }
      },
      "features": {
        "feature/category": {
          "camera_work": "視点/カメラワーク",
          "customization": "自由度",
          "experience": "体験内容",
          "influenced": "継承/インスパイア",
          "map_design": "マップ・空間の作り",
          "monetization": "課金の影響",
          "other": "その他",
          "replayability": "やりこみ/挑戦要素",
          "service": "運営/開発",
          "story": "ストーリー",
          "visual": "ビジュアル表現"
        },
        "feature/name": {
          "ad_supported": "広告表示あり",
          "anime": "アニメ風",
          "ar": "ARゲーム",
          "arena_shooter": "アリーナシューター",
          "auto_battle": "オートバトル",
          "automation": "自動化",
          "battle_royale": "バトルロイヤル",
          "blockchain_game": "ブロックチェーンゲーム",
          "branching_selection": "選択による分岐",
          "bullet_hell": "弾幕",
          "cartoony": "漫画風",
          "character_building": "キャラクタービルディング",
          "character_customization": "キャラクターメイク",
          "class_based": "クラス制",
          "collection": "収集要素",
          "combat": "戦闘",
          "crafting": "クラフト",
          "crowdfunded": "クラウドファウンド",
          "customize_gears": "装備調整/改造",
          "deck_building": "デッキ構築",
          "destruction": "破壊",
          "diablo_like": "ディアブロライク",
          "difficult": "高難易度",
          "dlc": "DLCあり",
          "eft_like": "タルコフライク",
          "episodic": "エピソード形式",
          "esports": "eスポーツ",
          "exploration": "探検/探索",
          "first_person": "一人称視点",
          "frequent_events": "イベントが豊富",
          "game_maker": "ゲームメーカー",
          "gamepad": "コントローラ対応(PC)",
          "hack_and_slash": "ハック＆スラッシュ",
          "hd2d": "HD-2D",
          "housing": "建築/ハウジング",
          "idle_game": "放置ゲーム",
          "indie": "インディーズ",
          "isometric": "斜め見下ろし",
          "jrpg": "JRPG",
          "lgbtq": "LGBTQ+",
          "linear": "直線的な進行",
          "lore_rich": "濃密な世界観/設定",
          "mature": "成人向け",
          "metroidvania": "メトロイドヴァニア",
          "minimal": "ミニマル",
          "mods": "MOD有効",
          "monster_taming": "モンスターテイミング",
          "multiple_endings": "マルチエンディング",
          "narrative_centric": "物語重視",
          "non_linear": "進め方が自由",
          "nsfw": "職場閲覧注意",
          "open_world": "オープンワールド",
          "p2w": "P2W（Pay to Win）",
          "parkour": "パルクール",
          "pay_to_win": "P2W（Pay to Win）",
          "permadeath": "パーマデス",
          "pixel": "ドット絵",
          "procedurally_generated": "プロシージャル生成",
          "randomized_loot": "装備厳選",
          "regular_updates": "アップデートが頻繁",
          "replayability": "周回プレイ",
          "rogue_like": "ローグライク",
          "rogue_lite": "ローグライト",
          "sandbox": "サンドボックス",
          "score_attack": "スコア挑戦",
          "season_pass": "シーズンパス",
          "sequel": "前作との繋がり",
          "short": "短時間",
          "side_scroll": "サイドスクロール",
          "six_dof": "全方向回転（6DOF）",
          "skin_acquisition": "スキン入手",
          "social_game": "ソーシャルゲーム",
          "souls_like": "ソウルライク",
          "split_screen": "画面分割",
          "survival": "サバイバル",
          "third_person": "三人称視点",
          "three_d": "3D空間",
          "time_attack": "タイムアタック",
          "top_down": "見下ろし",
          "touch_friendly": "タッチスクリーン",
          "vampiresuvivors_like": "ヴァンサバライク",
          "voxel": "ボクセルアート",
          "vr": "VRゲーム",
          "web": "ウェブ技術",
          "wrpg": "WRPG"
        }
      },
      "game_blog_themes": {
        "game_blog_theme": {
          "name": "投稿のテーマ"
        },
        "game_blog_theme/name": {
          "memories": "思い出",
          "other": "その他",
          "play_diary": "プレイ日記",
          "strategy": "攻略",
          "tips": "メモ/TIPS"
        }
      },
      "game_companies": {
        "game_company/category": {
          "developer": "デベロッパー",
          "publisher": "パブリッシャー"
        }
      },
      "game_localizations": {
        "game_localization": {
          "full_audio": "音声",
          "interface": "UI",
          "language": "言語",
          "note": "備考",
          "subtitles": "字幕"
        }
      },
      "game_related_works": {
        "game_related_work/category": {
          "original": "リメイク元",
          "previous": "前作"
        }
      },
      "game_releases": {
        "game_release": {
          "development_state": "開発状況",
          "release_date": "リリース日",
          "release_date_announce": "開発日の予告"
        },
        "game_release/development_state": {
          "alpha_or_beta_version": "α版またはβ版",
          "early_access": "早期アクセス版",
          "on_sale": "リリース中",
          "sales_ended": "販売・運営終了/開発中止",
          "under_development": "開発中"
        },
        "game_release/release_date_announce": {
          "about": "リリース予定時期",
          "fixed": "リリース日/予定日",
          "unknown": "不明"
        },
        "game_release/season": {
          "after": "以降",
          "april": "4月",
          "august": "8月",
          "december": "12月",
          "feburary": "2月",
          "first_half": "1月～6月（上半期）",
          "first_quarter": "1月～3月（第1四半期）",
          "fourth_quarter": "10月～12月（第4四半期）",
          "january": "1月",
          "july": "7月",
          "june": "6月",
          "march": "3月",
          "may": "5月",
          "november": "11月",
          "october": "10月",
          "second_half": "7月～12月（下半期）",
          "second_quarter": "4月～6月（第2四半期）",
          "september": "9月",
          "third_quarter": "7月～9月（第3四半期）",
          "until": "以内"
        },
        "game_release/season_category": {
          "at_least": "最短/最遅",
          "halves": "前半/後半を選択",
          "months": "月を選択",
          "quarters": "四半期を選択"
        },
        "software_formats": "ハードウェア"
      },
      "game_reviews": {
        "game_review": {
          "body": "レビュー内容",
          "not_recommended_messages": "おすすすめしない内容",
          "play_level": "プレイ度",
          "published_at": "公開日時",
          "recommended_messages": "おすすすめ内容",
          "software_formats": "使用したハード",
          "title": "記事タイトル"
        },
        "game_review/play_level": {
          "high": "一通りプレイした！",
          "highest": "かなりやり込んだ！",
          "low": "そこそこプレイした",
          "lowest": "少しだけプレイした"
        },
        "game_review/play_level/supplement": {
          "high": "（メインコンテンツは十分に体験した）",
          "highest": "（サブコンテンツなど含めて網羅的に体験した）",
          "low": "（途中で辞めたがゲーム性は十分に体験した）",
          "lowest": "（続ける気が起きずにすぐに辞めてしまった）"
        },
        "game_review/status": {
          "draft": "非公開",
          "published": "公開中"
        },
        "game_review/title": {
          "default": "タイトル未設定"
        }
      },
      "games": {
        "game": {
          "developers": "開発元",
          "official_url": "公式サイト",
          "publishers": "販売元"
        }
      },
      "genres": {
        "genre/name": {
          "action": "アクション",
          "action_advventure": "アクションアドベンチャー",
          "action_racing": "アクションレース",
          "action_rpg": "アクションRPG",
          "adventure": "アドベンチャー",
          "air_combat": "フライトシューティング",
          "american_football": "アメリカンフットボール",
          "amusement": "アミューズメント",
          "baseball": "野球",
          "basketball": "バスケットボール",
          "beat_em_up": "ベルトアクション",
          "billiards": "ビリヤード",
          "board_game": "ボードゲーム",
          "bowling": "ボーリング",
          "boxing": "ボクシング",
          "brain": "脳トレーニング",
          "car_racing": "カーレース",
          "card_game": "カードゲーム",
          "casino": "カジノゲーム",
          "casual": "カジュアル",
          "city_building": "シティビルディング",
          "crime_action": "クライムアクション",
          "dating": "恋愛ゲーム",
          "deduction": "ソーシャルディダクション",
          "educational": "教養/学習/運動",
          "escape": "脱出ゲーム",
          "extreme": "エクストリームスポーツ",
          "falling_block": "落ち物パズル",
          "fighting": "対戦型格闘ゲーム",
          "fishing": "フィッシング",
          "fitness": "健康/運動",
          "football": "サッカー",
          "four_x": "4X",
          "fps": "ファーストパーソンシューティング",
          "golf": "ゴルフ",
          "grand_strategy": "グランドストラテジー",
          "hockey": "ホッケー",
          "hunting": "ハンティング",
          "interactive_fiction": "テキストアドベンチャー",
          "jigsaw": "ジグソー",
          "kickboxing": "キックボクシング",
          "language": "語学",
          "life_simulation": "ライフシミュレーション",
          "logic": "ロジックパズル",
          "mahjong": "麻雀",
          "martial_arts": "格闘/武術",
          "matching_puzzle": "マッチングパズル",
          "math": "算数・数学",
          "minigame": "ミニゲームコレクション",
          "mma": "総合格闘技",
          "moba": "MOBA",
          "motorcycle": "モーターバイクレース",
          "offroad": "オフロードレース",
          "otome": "乙女ゲーム",
          "outdoor": "アウトドア",
          "pachinko": "パチンコ/パチスロ",
          "party": "パーティゲーム",
          "pinball": "ピンボール",
          "platform_action": "プラットフォームアクション",
          "point_and_click": "ポイント＆クリック",
          "pro_wrestling": "プロレス",
          "puzzle_solving": "パズル/謎解き",
          "quiz": "クイズゲーム",
          "racing": "レーシングゲーム",
          "rail_shooter": "ガンシューティング",
          "real_time_strategy": "リアルタイムストラテジー",
          "rhythm": "リズムゲーム",
          "rpg": "ロールプレイングゲーム",
          "rtstd": "対戦型タワーディフェンス",
          "rugby": "ラグビー",
          "run_and_gun": "アクションシューティング",
          "runner": "ランナー",
          "science": "科学・物理学",
          "shoot_em_up": "スクロールシューティング",
          "shooting": "シューティング",
          "simulation": "シミュレーション",
          "simulator": "専門シミュレーター",
          "slide_puzzle": "スライドパズル",
          "social": "歴史・地理",
          "sound": "サウンドノベル",
          "space_combat": "スペースコンバット",
          "sports": "スポーツゲーム",
          "stealth_action": "ステルスゲーム",
          "strategy": "ストラテジー",
          "tabletop_game": "テーブルゲーム",
          "tactical_rpg": "シミュレーションRPG",
          "tennis": "テニス",
          "tower_defense": "タワーディフェンス",
          "tps": "サードパーソンシューティング",
          "traditional_game": "伝統/古典ゲーム",
          "turn_based_rpg": "コマンドバトルRPG",
          "turn_based_strategy": "ターン制ストラテジー",
          "tycoon": "経営シミュレーション",
          "typing": "タイピングゲーム",
          "visual_novel": "ビジュアルノベル",
          "word_puzzle": "ワードパズル"
        }
      },
      "hardwares": {
        "hardware/aliases": {
          "android": [
            "アンドロイド",
            "スマホ",
            "スマートフォン",
            "モバイル",
            "ケータイ",
            "携帯"
          ],
          "dreamcast": [
            "ドリキャス",
            "どりきゃす",
            "ＤＣ"
          ],
          "game_boy": [
            "げーむぼーい",
            "ＧＢ",
            "GameBoy",
            "DMG-01"
          ],
          "gameboy_advance": [
            "げーむぼーいあどばんす",
            "GameBoy Advance",
            "ＧＢＡ"
          ],
          "gamecube": [
            "げーむきゅーぶ",
            "GameCube",
            "ＧＣ"
          ],
          "hi_saturn": [
            "さたーん",
            "セガサターン"
          ],
          "iphone": [
            "iPad",
            "iOS",
            "アイパッド",
            "アイフォン",
            "アイフォーン",
            "スマホ",
            "スマートフォン",
            "モバイル",
            "ケータイ",
            "携帯"
          ],
          "mark_3": [
            "マスターシステム"
          ],
          "nes": [
            "ＦＣ",
            "Family Computer",
            "ファミコン",
            "ふぁみこん"
          ],
          "nintendo64": [
            "64",
            "６４",
            "ロクヨン",
            "ろくよん"
          ],
          "nintendo_switch": [
            "スイッチ",
            "スウィッチ",
            "Ｓｗｉｔｃｈ",
            "NS",
            "ＮＳ"
          ],
          "nintendo_switch_el": [
            "スイッチEL",
            "スウィッチEL",
            "スイッチＥＬ",
            "スウィッチＥＬ",
            "有機EL",
            "Ｓｗｉｔｃｈ",
            "NS",
            "ＮＳ"
          ],
          "nintendo_switch_lite": [
            "スイッチライト",
            "スウィッチライト",
            "スイッチlite",
            "スウィッチlight",
            "スウィッチｌｉｔｅ",
            "Ｓｗｉｔｃｈ",
            "NS",
            "ＮＳ"
          ],
          "pc": [
            "Windows",
            "ウィンドウズ"
          ],
          "playstation": [
            "PS1",
            "ＰＳ１",
            "プレステ1"
          ],
          "playstation_2": [
            "ＰＳ２",
            "プレステ2"
          ],
          "playstation_3": [
            "ＰＳ３",
            "プレステ3"
          ],
          "playstation_4": [
            "ＰＳ４",
            "プレステ4"
          ],
          "playstation_5": [
            "ＰＳ５",
            "プレステ5"
          ],
          "sega_saturn": [
            "さたーん"
          ],
          "snes": [
            "ＳＦＣ",
            "Super Family Computer",
            "スーパーファミリーコンピュータ",
            "スーファミ",
            "すーふぁみ"
          ],
          "v_saturn": [
            "さたーん",
            "セガサターン"
          ],
          "vr_headset": [
            "Oculus",
            "オキュラス",
            "Meta",
            "メタ",
            "Quest",
            "クエスト",
            "HTC",
            "VIVE",
            "Pico",
            "Xperia",
            "エレコム",
            "Elecom"
          ],
          "wii": [
            "ｗｉｉ",
            "ウィ",
            "ウィイ",
            "ウィー"
          ],
          "wii_u": [
            "ｗｉｉｕ",
            "ウィユー",
            "ウィイユー",
            "ウィーユー",
            "ウィユウ",
            "ウィイユウ",
            "ウィーユウ"
          ],
          "xbox": [
            "ｘｂｏｘ",
            "ｘぼｘ",
            "箱",
            "X箱"
          ],
          "xbox_360": [
            "ｘｂｏｘ",
            "ｘぼｘ",
            "箱",
            "箱◯",
            "箱丸"
          ],
          "xbox_one": [
            "ｘｂｏｘ",
            "ｘぼｘ",
            "箱"
          ],
          "xbox_one_s": [
            "ｘｂｏｘ",
            "ｘぼｘ",
            "箱"
          ],
          "xbox_one_x": [
            "ｘｂｏｘ",
            "ｘぼｘ",
            "箱"
          ],
          "xbox_series_xs": [
            "ｘｂｏｘ",
            "ｘぼｘ",
            "箱"
          ]
        },
        "hardware/console": {
          "general": "ゲームプレイ可能なデバイス",
          "handheld": "ゲーム機（ポータブル）",
          "home": "ゲーム機（TV固定）"
        },
        "hardware/full_name": {
          "Intellivision 2": "インテレビジョン2",
          "action_max": "アクションマックス",
          "adventure_vision": "アドベンチャービジョン",
          "amiga_500": "Amiga 500",
          "amiga_cd32": "Amiga CD³²",
          "android": "Android",
          "apf_imagination_machine": "APFイマジネーションマシーン",
          "arcade_card": "アーケードカード",
          "arcadia": "アルカディア2001",
          "atari_2600": "Atari 2600",
          "atari_5200": "Atari 5200",
          "atari_7800": "Atari 7800",
          "atari_8bit": "Atari 8ビット・コンピュータ",
          "atari_jaguar": "Atari Jaguar",
          "atari_jaguar_cd": "Atari Jaguar CD",
          "atari_lynx": "Atari Lynx",
          "atari_st": "Atari ST",
          "atari_vcs": "Atari VCS",
          "atari_xegs": "Atari XEGS",
          "bally_astrocade": "バリー・アストロケイド",
          "bridge_companion": "ブリッジコンパニオン",
          "casettevision": "カセットビジョン",
          "casettevision_jr": "カセットビジョンJr.",
          "cd_i": "CD-i",
          "coleco_telstar_arcade": "コレコ・テルスターアーケード",
          "coleco_vision": "コレコビジョン",
          "color_tv_game": "カラーテレビゲーム",
          "commodore_64gs": "コモドール64 ゲームズシステム",
          "commodore_cdtv": "コモドールCDTV",
          "compact_vision": "TVボーイ",
          "cpc_plus": "CPC Plus",
          "cps_changer": "CPシステム",
          "creativision": "VTech CreatiVision",
          "disck_smith_wizzard": "Dick Smith Wizzard",
          "discover": "DIScover",
          "disk_system": "ディスクシステム",
          "dreamcast": "ドリームキャスト",
          "educat": "Educat 2002",
          "fairchild_channel_f": "フェアチャイルド・チャンネルF",
          "fm_town": "FMタウン",
          "fm_towns_marty": "FM TOWNS マーティー",
          "funbox": "FunBox",
          "funvision": "FunVision Computer Video Games System",
          "game_and_watch": "ゲーム＆ウオッチ",
          "game_boy": "ゲームボーイ",
          "game_boy_player": "ゲームボーイプレーヤー",
          "gameboy_advance": "ゲームボーイアドバンス",
          "gameboy_color": "ゲームボーイカラー",
          "gameboy_light": "ゲームボーイライト",
          "gameboy_pocket": "ゲームボーイポケット",
          "gamecube": "ゲームキューブ",
          "gamegear": "ゲームギア",
          "gamewave": "ゲームウェーブ",
          "gb_micro": "ゲームボーイミクロ",
          "gba_sp": "ゲームボーイアドバンスSP",
          "gce_vectrex": "コンピュータービジョン 光速船",
          "genesis": "メガドライブ",
          "gx4000": "GX4000",
          "hanimex_rameses": "Hanimex Rameses",
          "hi_saturn": "Hiサターン",
          "homepong": "ホームポン",
          "intellivision": "インテレビジョン",
          "iphone": "iPhone・iPad",
          "ique": "iQue Player",
          "laser_active": "レーザーアクティブ",
          "loopy": "ルーピー",
          "mad_catz_mojo": "Mad Catz M.O.J.O.",
          "mark_1": "SG-1000",
          "mark_2": "SG-1000 Ⅱ",
          "mark_3": "セガ・マークⅢ",
          "mega_adapter": "メガアダプタ",
          "mega_cd": "メガCD",
          "mega_pc": "MEGA PC",
          "my_vision": "マイビジョン",
          "neogeo": "ネオジオ",
          "neogeo_cd": "ネオジオCD",
          "neogeo_cdz": "ネオジオCD-Z",
          "neogeo_pocket": "ネオジオポケット",
          "neogeo_pocket_color": "ネオジオポケットカラー",
          "nes": "ファミリーコンピュータ",
          "new_nintendo_2ds_xl": "Newニンテンドー2DS LL",
          "new_nintendo_3ds": "Newニンテンドー3DS",
          "new_nintendo_3ds_xl": "Newニンテンドー3DS LL",
          "nintendo64": "NINTENDO64",
          "nintendo64_dd": "64DD",
          "nintendo_2ds": "ニンテンドー2DS",
          "nintendo_3ds": "ニンテンドー3DS",
          "nintendo_3ds_xl": "ニンテンドー3DS XL",
          "nintendo_ds": "ニンテンドーDS",
          "nintendo_ds_i": "ニンテンドーDSi",
          "nintendo_ds_i_xl": "ニンテンドーDSi XL",
          "nintendo_ds_lite": "ニンテンドーDS Lite",
          "nintendo_switch": "Nintendo Switch",
          "nintendo_switch_el": "Nintendo Switch（有機ELモデル）",
          "nintendo_switch_lite": "Nintendo Switch Lite",
          "nomad": "ノーマッド",
          "nuon": "NUON",
          "odyssey": "マグナボックス・オデッセイ",
          "odyssey_2": "マグナボックス・オデッセイ2",
          "ouya": "OUYA",
          "panasonic_q": "Panasonic Q",
          "pc": "パソコン",
          "pc50x": "ビデオカセッティ・ロック",
          "pcfx": "PC-FX",
          "pippin_atmark": "ピピンアットマーク",
          "playdia": "Playdia",
          "playstation": "プレイステーション",
          "playstation_2": "プレイステーション2",
          "playstation_3": "プレイステーション3",
          "playstation_4": "プレイステーション4",
          "playstation_4_pro": "プレイステーション4 Pro",
          "playstation_4_slim": "プレイステーション4 スリム",
          "playstation_5": "プレイステーション5",
          "playstation_classic": "プレイステーション クラシック",
          "playstation_tv": "プレイステーションTV",
          "playstation_vita": "プレイステーションヴィータ",
          "ps_one": "PS One",
          "psp": "プレイステーション・ポータブル",
          "psp_go": "プレイステーション・ポータブル go",
          "psx": "PSX",
          "pv1000": "PV-1000",
          "pv2000": "PV-2000",
          "rca_studio_2": "RCA Studio II",
          "satellaview": "サテラビュー",
          "sc_3000": "SC-3000",
          "sega_32x": "スーパー32X",
          "sega_multimega": "CDX",
          "sega_saturn": "セガサターン",
          "snes": "スーパーファミコン",
          "steam_deck": "Steam Deck",
          "steam_deck_oled": "Steam Deck OLED",
          "super_acan": "Super A'Can",
          "super_cassette_vision": "スーパーカセットビジョン",
          "super_cd_rom2": "スーパーCD-ROM²",
          "super_game_boy": "スーパーゲームボーイ",
          "super_grafx": "PCエンジンスーパーグラフィックス",
          "super_vision": "TVジャック",
          "three_do": "3DO",
          "turbo_duo": "PCエンジンDuo",
          "turbo_duo_r": "PCエンジンDuo-R",
          "turbo_express": "PCエンジンGT",
          "turbo_grafx": "PCエンジン",
          "turbo_grafx_cd": "CD-ROM²",
          "tutor": "トミー ぴゅう太",
          "tutor_jr": "トミー ぴゅう太 Jr.",
          "tutor_mk2": "トミー ぴゅう太 mk2",
          "twelve_ninety_two_apvs": "1292 APVS",
          "twin_famicom": "ツインファミコン",
          "ultimax": "マックスマシーン",
          "v_flash": "V.Flash",
          "v_saturn": "Vサターン",
          "v_smile": "V.Smile",
          "vc4000": "ビデオコンピュータ VC4000",
          "video_challenger": "ビデオチャレンジャー",
          "virtual_boy": "バーチャルボーイ",
          "vis": "VIS",
          "vr_headset": "VRゴーグル",
          "vtech_socrates": "VTech Socrates",
          "wii": "Wii",
          "wii_u": "Wii U",
          "wonder_swan": "ワンダースワン",
          "wonder_swan_color": "ワンダースワンカラー",
          "xavix": "ザビックスポート",
          "xbox": "XBOX",
          "xbox_360": "XBOX 360",
          "xbox_one": "Xbox One",
          "xbox_one_s": "Xbox One S",
          "xbox_one_x": "Xbox One X",
          "xbox_series_xs": "Xbox Series X/S",
          "xeye": "ワンダーメガ",
          "zeebo": "Zeebo"
        },
        "hardware/name": {
          "Intellivision 2": "インテレビジョン2",
          "action_max": "アクションマックス",
          "adventure_vision": "アドベンチャービジョン",
          "amiga_500": "Amiga 500",
          "amiga_cd32": "Amiga CD³²",
          "android": "Android",
          "apf_imagination_machine": "イマジネーションマシン",
          "arcade_card": "アーケードカード",
          "arcadia": "アルカディア",
          "atari_2600": "Atari 2600",
          "atari_5200": "Atari 5200",
          "atari_7800": "Atari 7800",
          "atari_8bit": "Atari 8ビット",
          "atari_jaguar": "Atari Jaguar",
          "atari_jaguar_cd": "Atari Jaguar CD",
          "atari_lynx": "Atari Lynx",
          "atari_st": "Atari ST",
          "atari_vcs": "Atari VCS",
          "atari_xegs": "Atari XEGS",
          "bally_astrocade": "バリー・アストロケイド",
          "bridge_companion": "ブリッジコンパニオン",
          "casettevision": "カセットビジョン",
          "casettevision_jr": "カセットビジョンJr.",
          "cd_i": "CD-i",
          "coleco_telstar_arcade": "コレコ・テルスター",
          "coleco_vision": "コレコビジョン",
          "color_tv_game": "カラーTVゲーム",
          "commodore_64gs": "C64GS",
          "commodore_cdtv": "CDTV",
          "compact_vision": "TVボーイ",
          "cpc_plus": "CPC Plus",
          "cps_changer": "CPシステム",
          "creativision": "CreatiVision",
          "disck_smith_wizzard": "DSW",
          "discover": "DIScover",
          "disk_system": "ディスクシステム",
          "dreamcast": "DC",
          "educat": "Educat",
          "fairchild_channel_f": "フェアチャイルド・チャンネルF",
          "fm_town": "FMタウン",
          "fm_towns_marty": "マーティー",
          "funbox": "FunBox",
          "funvision": "FunVision",
          "game_and_watch": "GAME&WATCH",
          "game_boy": "GB",
          "game_boy_player": "GBプレーヤー",
          "gameboy_advance": "GBA",
          "gameboy_color": "GBC",
          "gameboy_light": "GB Light",
          "gameboy_pocket": "GB Pocket",
          "gamecube": "GC",
          "gamegear": "ゲームギア",
          "gamewave": "GameWave",
          "gb_micro": "GBM",
          "gba_sp": "GBA SP",
          "gce_vectrex": "光速船",
          "genesis": "MD",
          "gx4000": "GX4000",
          "hanimex_rameses": "Hanimex",
          "hi_saturn": "Hiサターン",
          "homepong": "ホームポン",
          "intellivision": "インテレビジョン",
          "iphone": "iPhone",
          "ique": "iQue",
          "laser_active": "レーザーアクティブ",
          "loopy": "ルーピー",
          "mad_catz_mojo": "MOJO",
          "mark_1": "SG-1000",
          "mark_2": "SG-1000 Ⅱ",
          "mark_3": "マークⅢ",
          "mega_adapter": "メガアダプタ",
          "mega_cd": "メガCD",
          "mega_pc": "MEGA PC",
          "my_vision": "マイビジョン",
          "neogeo": "NEOGEO",
          "neogeo_cd": "NEOGEO CD",
          "neogeo_cdz": "CD-Z",
          "neogeo_pocket": "NGP",
          "neogeo_pocket_color": "NGPC",
          "nes": "FC",
          "new_nintendo_2ds_xl": "N2DS LL",
          "new_nintendo_3ds": "N3DS",
          "new_nintendo_3ds_xl": "N3DS LL",
          "nintendo64": "64",
          "nintendo64_dd": "64DD",
          "nintendo_2ds": "2DS",
          "nintendo_3ds": "3DS",
          "nintendo_3ds_xl": "3DS XL",
          "nintendo_ds": "DS",
          "nintendo_ds_i": "DSi",
          "nintendo_ds_i_xl": "DSi XL",
          "nintendo_ds_lite": "DS Lite",
          "nintendo_switch": "Switch",
          "nintendo_switch_el": "Switch(EL)",
          "nintendo_switch_lite": "Switch(Lite)",
          "nomad": "ノーマッド",
          "nuon": "NUON",
          "odyssey": "オデッセイ",
          "odyssey_2": "オデッセイ2",
          "ouya": "OUYA",
          "panasonic_q": "Q",
          "pc": "PC",
          "pc50x": "ビデオカセッティ",
          "pcfx": "PC-FX",
          "pippin_atmark": "ピピン",
          "playdia": "Playdia",
          "playstation": "PS",
          "playstation_2": "PS2",
          "playstation_3": "PS3",
          "playstation_4": "PS4",
          "playstation_4_pro": "PS4 Pro",
          "playstation_4_slim": "PS4 Slim",
          "playstation_5": "PS5",
          "playstation_classic": "PS Classic",
          "playstation_tv": "PSTV",
          "playstation_vita": "PS Vita",
          "ps_one": "PS One",
          "psp": "PSP",
          "psp_go": "PSP go",
          "psx": "PSX",
          "pv1000": "PV-1000",
          "pv2000": "PV-2000",
          "rca_studio_2": "RCA2",
          "satellaview": "サテラビュー",
          "sc_3000": "SC-3000",
          "sega_32x": "スーパー32X",
          "sega_multimega": "CDX",
          "sega_saturn": "SS",
          "snes": "SFC",
          "steam_deck": "SD",
          "steam_deck_oled": "SD OLED",
          "super_acan": "Super A'Can",
          "super_cassette_vision": "スーパーカセットビジョン",
          "super_cd_rom2": "スーパーCD-ROM²",
          "super_game_boy": "SUPER GB",
          "super_grafx": "スーパーグラフィックス",
          "super_vision": "TVジャック",
          "three_do": "3DO",
          "turbo_duo": "PCエンジンDuo",
          "turbo_duo_r": "PCエンジンDUO-R",
          "turbo_express": "GT",
          "turbo_grafx": "PC-E",
          "turbo_grafx_cd": "ROM²",
          "tutor": "ぴゅう太",
          "tutor_jr": "ぴゅう太 Jr.",
          "tutor_mk2": "ぴゅう太 mk2",
          "twelve_ninety_two_apvs": "1292 APVS",
          "twin_famicom": "ツインファミコン",
          "ultimax": "マックスマシーン",
          "v_flash": "V.Flash",
          "v_saturn": "Vサターン",
          "v_smile": "V.Smile",
          "vc4000": "VC4000",
          "video_challenger": "ビデオチャレンジャー",
          "virtual_boy": "VB",
          "vis": "VIS",
          "vr_headset": "VR",
          "vtech_socrates": "Socrates",
          "wii": "Wii",
          "wii_u": "Wii U",
          "wonder_swan": "WS",
          "wonder_swan_color": "WSC",
          "xavix": "XaviX PORT",
          "xbox": "Xb",
          "xbox_360": "Xb360",
          "xbox_one": "XONE",
          "xbox_one_s": "XONE S",
          "xbox_one_x": "XONE X",
          "xbox_series_xs": "Xbox X/S",
          "xeye": "ワンダーメガ",
          "zeebo": "Zeebo"
        }
      },
      "languages": {
        "language/code": {
          "af": "アフリカーンス語",
          "ar": "アラビア語",
          "be": "ベラルーシ語",
          "bg": "ブルガリア語",
          "cs": "チェコ語",
          "da": "デンマーク語",
          "de": "ドイツ語",
          "el": "ギリシャ語",
          "en": "英語",
          "es": "スペイン語",
          "et": "エストニア語",
          "false": "ノルウェー語",
          "fil": "フィリピノ語",
          "fr": "フランス語",
          "ga": "アイルランド語",
          "he": "ヘブライ語",
          "hi": "ヒンディー語",
          "hr": "クロアチア語",
          "hu": "ハンガリー語",
          "id": "インドネシア語",
          "is": "アイスランド語",
          "ja": "日本語",
          "kk": "カザフ語",
          "ko": "韓国語",
          "lt": "リトアニア語",
          "lv": "ラトビア語",
          "ms": "マレー語",
          "mt": "マルタ語",
          "nl": "オランダ語",
          "pl": "ポーランド語",
          "pt": "ポルトガル語",
          "ro": "ルーマニア語",
          "ru": "ロシア語",
          "sk": "スロバキア語",
          "sl": "スロベニア語",
          "sr": "セルビア語",
          "sv": "スウェーデン語",
          "th": "タイ語",
          "tr": "トルコ語",
          "uk": "ウクライナ語",
          "vi": "ベトナム語",
          "zh-CN": "簡体中国語"
        },
        "language/popularity": {
          "high": "主要言語",
          "low": "その他の言語"
        }
      },
      "locales": {
        "locale/code": {
          "en": "英語",
          "ja": "日本語",
          "zh-CN": "簡体中国語"
        },
        "locale/popularity": {
          "high": "主要言語",
          "low": "その他の言語"
        }
      },
      "maturity_rating_systems": {
        "maturity_rating_system/name": {
          "ACB": "ACB",
          "BBFC": "BBFC",
          "CADPA": "CADPA",
          "CERO": "CERO",
          "DJCTQ": "DJCTQ",
          "EOCS": "EOCS",
          "ESRA": "ESRA",
          "ESRB": "ESRB",
          "GRAC": "GRAC",
          "GRB": "GRB",
          "GSRR": "GSRR",
          "IARC": "IARC",
          "OLFC_AU": "OLFC(AU)",
          "OLFC_NZ": "OLFC(NZ)",
          "PEGI": "PEGI",
          "SEGOB": "SEGOB",
          "USK": "USK",
          "app_store": "AppStore"
        }
      },
      "maturity_ratings": {
        "maturity_rating/name": {
          "acb_0": "General",
          "acb_12": "Mature",
          "acb_15": "MA15+",
          "acb_18": "R18+",
          "acb_7": "Parental Guidance",
          "bbfc_12": "12",
          "bbfc_12a": "12A",
          "bbfc_15": "15",
          "bbfc_18": "18",
          "bbfc_pg": "PG",
          "bbfc_r18": "R18",
          "bbfc_u": "U",
          "cadpa_12": "12+",
          "cadpa_16": "16+",
          "cadpa_8": "8+",
          "cero_a": "A(全年齢対象)",
          "cero_b": "B(12歳以上対象)",
          "cero_c": "C(15歳以上対象)",
          "cero_d": "D(17歳以上対象)",
          "cero_educational": "教育・データベース",
          "cero_planned": "審査予定",
          "cero_trial": "規定適合",
          "cero_z": "Z(18歳以上のみ対象)",
          "djctq_10": "10",
          "djctq_12": "12",
          "djctq_14": "14",
          "djctq_16": "16",
          "djctq_18": "18",
          "djctq_er": "ER",
          "djctq_l": "L",
          "eocs_0": "一般ソフト作品（一般作品）",
          "eocs_12": "一般ソフト作品（１２歳以上推奨作品）",
          "eocs_15": "一般ソフト作品（１５歳以上推奨作品）",
          "eocs_18": "１８才未満者への販売禁止ソフト作品（１８禁作品）",
          "esra_12": "+12",
          "esra_15": "+15",
          "esra_18": "+18",
          "esra_25": "+25",
          "esra_3": "+3",
          "esra_7": "+7",
          "esrb_ao": "AO",
          "esrb_e": "E",
          "esrb_e10": "E10+",
          "esrb_ec": "eC",
          "esrb_m": "M",
          "esrb_rp": "RP",
          "esrb_t": "T",
          "grac_0": "전체이용가 (All)",
          "grac_12": "12세이용가 (12+)",
          "grac_15": "15세이용가 (15+)",
          "grac_18": "청소년이용불가 (18+)",
          "grb_0": "전체이용가 (All)",
          "grb_12": "12세이용가 (★)",
          "grb_15": "15세이용가 (★★)",
          "grb_18": "청소년이용불가 (★★★)",
          "gsrr_0": "0+ 普遍級",
          "gsrr_12": "12+ 輔12級",
          "gsrr_15": "15+ 輔15級",
          "gsrr_18": "18+ 限制級",
          "gsrr_6": "6+ 保護級",
          "iarc_12": "12+",
          "iarc_16": "16+",
          "iarc_18": "18+",
          "iarc_3": "3+",
          "iarc_7": "7+",
          "oflc_au_0": "General",
          "oflc_au_13": "R13",
          "oflc_au_15": "R15",
          "oflc_au_16": "R16",
          "oflc_au_18": "R18+",
          "oflc_au_7": "Parental Guidance",
          "oflc_au_mature": "Mature",
          "oflc_nz_g": "G",
          "oflc_nz_m": "M",
          "oflc_nz_pg": "PG",
          "oflc_nz_r": "Restricted",
          "oflc_nz_r13": "Restricted 13",
          "oflc_nz_r15": "Restricted 15",
          "oflc_nz_r16": "Restricted 16",
          "oflc_nz_r18": "Restricted 18",
          "oflc_nz_rp13": "RP13",
          "oflc_nz_rp16": "RP16",
          "oflc_nz_rp18": "RP18",
          "pegi_0": "!",
          "pegi_12": "12",
          "pegi_16": "16",
          "pegi_18": "18",
          "pegi_3": "3",
          "pegi_7": "7",
          "segob_a": "A",
          "segob_b": "B",
          "segob_b15": "B15",
          "segob_c": "C",
          "segob_d": "D",
          "segob_p": "P",
          "usk_0": "USK ab 0 freigegeben",
          "usk_12": "USK ab 12 freigegeben",
          "usk_16": "USK ab 16 freigegeben",
          "usk_18": "USK ab 18",
          "usk_6": "USK ab 6 freigegeben"
        }
      },
      "multi_play_mode_contents": {
        "multi_play_mode_content/category": {
          "battle_mode": "モードと参加人数",
          "connection_method": "接続方式",
          "other": "その他"
        },
        "multi_play_mode_content/name": {
          "asymmetric": "非対称戦",
          "battle_royale": "バトルロイヤル",
          "cable_of_wireless": "ケーブル/無線通信",
          "coop": "協力プレイ",
          "cross_platform": "クロスプラットフォーム",
          "deathmatch": "デスマッチ",
          "mmo": "MMO",
          "multiple_controller": "複数コントローラ",
          "one_vs_one": "2人対戦モード",
          "online": "オンラインプレイ",
          "rank_match": "ランクマッチ",
          "remote_play": "リモートプレイ",
          "skill_based_match_making": "スキルベースドマッチメイキング(SBMM)",
          "sociality": "ソーシャル性/ゆるい繋がり",
          "team": "チーム戦",
          "voice_chat": "ボイスチャット内蔵"
        }
      },
      "play_modes": {
        "play_mode/name": {
          "multi": "マルチプレイ",
          "single": "シングルプレイ"
        }
      },
      "sales_platforms": {
        "sales_platform/name": {
          "amazon_jp": "Amazon JP",
          "apple_store": "Apple Store",
          "ea": "EA",
          "epic_games": "Epic Games",
          "google_play": "Google Play",
          "microsoft": "Microsoft",
          "my_nintendo_store_jp": "My Nintendo Store(JP)",
          "ps_store": "PS Store",
          "steam": "Steam",
          "twitch": "Twitch",
          "ubisoft": "Ubisoft",
          "xbox_store": "Xbox Store",
          "youtube": "YouTube"
        }
      },
      "software_formats": {
        "software_format/console": {
          "general": "ゲームプレイ可能なデバイス",
          "handheld": "ゲーム機（ポータブル）",
          "home": "ゲーム機（TV固定）"
        },
        "software_format/full_name": {
          "action_max": "アクションマックス",
          "adventure_vision": "アドベンチャービジョン",
          "amiga_500": "Amiga 500",
          "amiga_cd32": "Amiga CD³²",
          "android": "Android",
          "apf_imagination_machine": "APFイマジネーションマシーン",
          "arcade_card": "アーケードカード",
          "arcadia": "アルカディア2001",
          "atari_2600": "Atari 2600",
          "atari_5200": "Atari 5200",
          "atari_7800": "Atari 7800",
          "atari_8bit": "Atari 8ビット・コンピュータ",
          "atari_jaguar": "Atari Jaguar",
          "atari_jaguar_cd": "Atari Jaguar CD",
          "atari_lynx": "Atari Lynx",
          "atari_st": "Atari ST",
          "atari_xegs": "Atari XEGS",
          "bally_astrocade": "バリー・アストロケイド",
          "bridge_companion": "ブリッジコンパニオン",
          "casettevision": "カセットビジョン",
          "cd_i": "CD-i",
          "coleco_telstar_arcade": "コレコ・テルスターアーケード",
          "coleco_vision": "コレコビジョン",
          "color_tv_game": "カラーテレビゲーム",
          "commodore_64gs": "コモドール64 ゲームズシステム",
          "commodore_cdtv": "コモドールCDTV",
          "compact_vision": "TVボーイ",
          "cpc_plus": "CPC Plus",
          "cps_changer": "CPシステム",
          "creativision": "VTech CreatiVision",
          "discover": "DIScover",
          "disk_system": "ディスクシステム",
          "dreamcast": "ドリームキャスト",
          "fairchild_channel_f": "フェアチャイルド・チャンネルF",
          "fm_town": "FMタウン",
          "fm_towns_marty": "FM TOWNS マーティー",
          "funbox": "FunBox",
          "game_and_watch": "ゲーム＆ウオッチ",
          "game_boy": "ゲームボーイ",
          "gameboy_advance": "ゲームボーイアドバンス",
          "gameboy_color": "ゲームボーイカラー",
          "gamecube": "ゲームキューブ",
          "gamegear": "ゲームギア",
          "gamewave": "ゲームウェーブ",
          "gce_vectrex": "コンピュータービジョン 光速船",
          "genesis": "メガドライブ",
          "gx4000": "GX4000",
          "homepong": "ホームポン",
          "intellivision": "インテレビジョン",
          "iphone": "iPhone・iPad",
          "laser_active": "レーザーアクティブ",
          "loopy": "ルーピー",
          "mad_catz_mojo": "Mad Catz M.O.J.O.",
          "mark_1": "SG-1000",
          "mark_3": "セガ・マークⅢ",
          "mega_cd": "メガCD",
          "mega_pc": "MEGA PC",
          "my_vision": "マイビジョン",
          "neogeo": "ネオジオ",
          "neogeo_cd": "ネオジオCD",
          "neogeo_pocket": "ネオジオポケット",
          "neogeo_pocket_color": "ネオジオポケットカラー",
          "nes": "ファミリーコンピュータ",
          "new_nintendo_3ds": "Newニンテンドー3DS",
          "nintendo64": "NINTENDO64",
          "nintendo64_dd": "64DD",
          "nintendo_3ds": "ニンテンドー3DS",
          "nintendo_ds": "ニンテンドーDS",
          "nintendo_ds_i": "ニンテンドーDSi",
          "nintendo_switch": "Nintendo Switch",
          "nuon": "NUON",
          "odyssey": "マグナボックス・オデッセイ",
          "odyssey_2": "マグナボックス・オデッセイ2",
          "ouya": "OUYA",
          "pc": "PCゲーム",
          "pc50x": "ビデオカセッティ・ロック",
          "pcfx": "PC-FX",
          "pippin_atmark": "ピピンアットマーク",
          "playdia": "Playdia",
          "playstation": "プレイステーション",
          "playstation_2": "プレイステーション2",
          "playstation_3": "プレイステーション3",
          "playstation_4": "プレイステーション4",
          "playstation_5": "プレイステーション5",
          "playstation_vita": "プレイステーションヴィータ",
          "psp": "プレイステーション・ポータブル",
          "pv1000": "PV-1000",
          "pv2000": "PV-2000",
          "rca_studio_2": "RCA Studio II",
          "satellaview": "サテラビュー",
          "sc_3000": "SC-3000",
          "sega_multimega": "CDX",
          "sega_saturn": "セガサターン",
          "snes": "スーパーファミコン",
          "steam_deck": "Steam Deck",
          "super_acan": "Super A'Can",
          "super_cassette_vision": "スーパーカセットビジョン",
          "super_cd_rom2": "スーパーCD-ROM²",
          "super_grafx": "PCエンジンスーパーグラフィックス",
          "super_vision": "TVジャック",
          "three_do": "3DO",
          "turbo_grafx": "PCエンジン",
          "turbo_grafx_cd": "CD-ROM²",
          "tutor": "トミー ぴゅう太",
          "twelve_ninety_two_apvs": "1292 APVS",
          "ultimax": "マックスマシーン",
          "v_flash": "V.Flash",
          "v_smile": "V.Smile",
          "video_challenger": "ビデオチャレンジャー",
          "virtual_boy": "バーチャルボーイ",
          "vr_headset": "VRゴーグル",
          "vtech_socrates": "VTech Socrates",
          "wii": "Wii",
          "wii_u": "Wii U",
          "wonder_swan": "ワンダースワン",
          "wonder_swan_color": "ワンダースワンカラー",
          "xavix": "ザビックスポート",
          "xbox": "XBOX",
          "xbox_360": "XBOX 360",
          "xbox_one": "Xbox One",
          "xbox_series_xs": "Xbox Series X/S",
          "zeebo": "Zeebo"
        },
        "software_format/name": {
          "action_max": "アクションマックス",
          "adventure_vision": "アドベンチャービジョン",
          "amiga_500": "Amiga 500",
          "amiga_cd32": "Amiga CD³²",
          "android": "Android",
          "apf_imagination_machine": "イマジネーションマシン",
          "arcade_card": "アーケードカード",
          "arcadia": "アルカディア",
          "atari_2600": "Atari 2600",
          "atari_5200": "Atari 5200",
          "atari_7800": "Atari 7800",
          "atari_8bit": "Atari 8ビット",
          "atari_jaguar": "Atari Jaguar",
          "atari_jaguar_cd": "Atari Jaguar CD",
          "atari_lynx": "Atari Lynx",
          "atari_st": "Atari ST",
          "atari_xegs": "Atari XEGS",
          "bally_astrocade": "バリー・アストロケイド",
          "bridge_companion": "ブリッジコンパニオン",
          "casettevision": "カセットビジョン",
          "cd_i": "CD-i",
          "coleco_telstar_arcade": "コレコ・テルスター",
          "coleco_vision": "コレコビジョン",
          "color_tv_game": "カラーTVゲーム",
          "commodore_64gs": "C64GS",
          "commodore_cdtv": "CDTV",
          "compact_vision": "TVボーイ",
          "cpc_plus": "CPC Plus",
          "cps_changer": "CPシステム",
          "creativision": "CreatiVision",
          "discover": "DIScover",
          "disk_system": "ディスクシステム",
          "dreamcast": "DC",
          "fairchild_channel_f": "フェアチャイルド・チャンネルF",
          "fm_town": "FMタウン",
          "fm_towns_marty": "マーティー",
          "funbox": "FunBox",
          "game_and_watch": "GAME&WATCH",
          "game_boy": "GB",
          "gameboy_advance": "GBA",
          "gameboy_color": "GBC",
          "gamecube": "GC",
          "gamegear": "ゲームギア",
          "gamewave": "GameWave",
          "gce_vectrex": "光速船",
          "genesis": "MD",
          "gx4000": "GX4000",
          "homepong": "ホームポン",
          "intellivision": "インテレビジョン",
          "iphone": "iPhone",
          "laser_active": "レーザーアクティブ",
          "loopy": "ルーピー",
          "mad_catz_mojo": "MOJO",
          "mark_1": "SG-1000",
          "mark_3": "マークⅢ",
          "mega_cd": "メガCD",
          "mega_pc": "MEGA PC",
          "my_vision": "マイビジョン",
          "neogeo": "NEOGEO",
          "neogeo_cd": "NEOGEO CD",
          "neogeo_pocket": "NGP",
          "neogeo_pocket_color": "NGPC",
          "nes": "FC",
          "new_nintendo_3ds": "N3DS",
          "nintendo64": "64",
          "nintendo64_dd": "64DD",
          "nintendo_3ds": "3DS",
          "nintendo_ds": "DS",
          "nintendo_ds_i": "DSi",
          "nintendo_switch": "Switch",
          "nuon": "NUON",
          "odyssey": "オデッセイ",
          "odyssey_2": "オデッセイ2",
          "ouya": "OUYA",
          "pc": "PC",
          "pc50x": "ビデオカセッティ",
          "pcfx": "PC-FX",
          "pippin_atmark": "ピピン",
          "playdia": "Playdia",
          "playstation": "PS",
          "playstation_2": "PS2",
          "playstation_3": "PS3",
          "playstation_4": "PS4",
          "playstation_5": "PS5",
          "playstation_vita": "PS Vita",
          "psp": "PSP",
          "pv1000": "PV-1000",
          "pv2000": "PV-2000",
          "rca_studio_2": "RCA2",
          "satellaview": "サテラビュー",
          "sc_3000": "SC-3000",
          "sega_multimega": "CDX",
          "sega_saturn": "SS",
          "snes": "SFC",
          "steam_deck": "SD",
          "super_acan": "Super A'Can",
          "super_cassette_vision": "スーパーカセットビジョン",
          "super_cd_rom2": "スーパーCD-ROM²",
          "super_grafx": "スーパーグラフィックス",
          "super_vision": "TVジャック",
          "three_do": "3DO",
          "turbo_grafx": "PC-E",
          "turbo_grafx_cd": "ROM²",
          "tutor": "ぴゅう太",
          "twelve_ninety_two_apvs": "1292 APVS",
          "ultimax": "マックスマシーン",
          "v_flash": "V.Flash",
          "v_smile": "V.Smile",
          "video_challenger": "ビデオチャレンジャー",
          "virtual_boy": "VB",
          "vr_headset": "VR",
          "vtech_socrates": "Socrates",
          "wii": "Wii",
          "wii_u": "Wii U",
          "wonder_swan": "WS",
          "wonder_swan_color": "WSC",
          "xavix": "XaviX PORT",
          "xbox": "Xb",
          "xbox_360": "Xb360",
          "xbox_one": "XONE",
          "xbox_series_xs": "Xbox X/S",
          "zeebo": "Zeebo"
        }
      },
      "themes": {
        "theme/category": {
          "atmosphere": "雰囲気",
          "behavior": "目的/行動",
          "character": "キャラクター",
          "depiction": "表現/描写",
          "stage": "場所/舞台",
          "time_period": "時代感",
          "vehicles": "乗り物",
          "worldview": "世界観"
        },
        "theme/name": {
          "agriculture": "農業/農家",
          "ai": "AI",
          "aircraft": "戦闘機/飛行機",
          "alien": "エイリアン",
          "ancient": "古代",
          "animals": "動物",
          "base_building": "基地建設",
          "battleship": "戦艦",
          "blood": "流血表現",
          "car": "車",
          "comedy": "コメディ",
          "contemporary": "現代",
          "continent": "大陸",
          "cooking": "料理",
          "country": "国",
          "crime": "犯罪表現",
          "cyberpunk": "サイバーパンク",
          "dark": "ダーク",
          "demon": "悪魔",
          "detective": "探偵",
          "dinosaur": "恐竜",
          "discriminatory": "差別表現",
          "drug": "薬物描写",
          "dystopia": "ディストピア",
          "earth": "地球",
          "economy": "経済/貿易",
          "exhilaration": "爽快感",
          "faith": "宗教的/信仰",
          "fantasy": "ファンタジー",
          "funny": "可笑しい",
          "gambling": "ギャンブル要素",
          "ghost": "幽霊",
          "gliding": "グライダー",
          "gore": "ゴア表現",
          "hacking": "ハッキング",
          "heartwarming": "ほのぼの",
          "historical": "歴史",
          "horror": "ホラー",
          "island": "島",
          "mars": "火星",
          "medieval": "中世",
          "modern": "近代",
          "motorcycle": "バイク",
          "movie": "映画",
          "mythology": "神話",
          "nature": "自然",
          "near_future": "近未来",
          "ninja": "忍者",
          "nostalgia": "ノスタルジー",
          "philosopical": "哲学的",
          "pirates": "海賊",
          "political": "政治的/社会",
          "post_apocalyptic": "ポストアポカリプス",
          "profanity": "言葉遣い",
          "psychedelic": "サイケデリック（幻想/幻覚的）",
          "psycological": "サイコ（狂気的）",
          "realistic": "リアル",
          "retro": "レトロ",
          "robots": "ロボット",
          "sailing": "帆走",
          "satire": "社会風刺",
          "school": "学校",
          "science_fiction": "SF",
          "sea": "海",
          "sexual": "性的表現",
          "spaceship": "宇宙船",
          "speed_boat": "スピードボート",
          "speedy": "スピード感",
          "steam_punk": "スチームパンク",
          "submarine": "潜水艦",
          "surreal": "独創的",
          "tank": "戦車",
          "thrilling": "緊張感",
          "time_manipulation": "時間操作",
          "time_travel": "タイムトラベル",
          "town": "街",
          "train": "電車",
          "underground": "地下",
          "underwater": "水中",
          "universe": "宇宙",
          "vampire": "ヴァンパイア",
          "villain": "主人公が悪役側",
          "violence": "暴力表現",
          "war": "戦争",
          "western": "ウェスタン",
          "zombie": "ゾンビ"
        }
      },
      "video_platforms": {
        "video_platform/name": {
          "twitch": "Twitch",
          "youtube": "YouTube"
        }
      }
    }
  }
}