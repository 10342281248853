import { createI18n } from 'vue-i18n'
import type { I18nOptions } from 'vue-i18n'
import messages from '../locales/compiled.json'
import type { InjectionKey } from "vue"

type MessageSchema = typeof messages.ja

const datetimeFormats: I18nOptions["datetimeFormats"] = {
  ja: {
    month: {
      year: 'numeric',
      month: 'short'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  }
}

export const createMyI18n = (locale: string) => {
  return createI18n<[MessageSchema], 'ja'>({
    locale,
    legacy: false,
    availableLocales: ["ja"],
    messages,
    datetimeFormats: {
      ja: datetimeFormats.ja
    }
  })
}

export type LocaleCode = "ja" | "en" | "zh-CN"
export const LocaleCodeKey: InjectionKey<LocaleCode> = Symbol('localeCode')
export const defaultLocaleCode = "ja"
