import { createApp, h } from 'vue'
import { createMyI18n } from './i18n'
import { initSentryInVue } from './sentry'

export const createMyApp = (App: any, props: any) => {
  const app = createApp({
    render: () => h(App, props)
  })
  initSentryInVue(app);
  app.use(createMyI18n(props.locale));
  return app;
}
